import React, { useState, useEffect } from "react";

import Header from "./components/Header";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Footer from "./components/Footer";

function App() {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("isDarkMode") || false
  );
  useEffect(() => {
    darkMode && document.body.classList.add("dark");
  }, [darkMode]);
  return (
    <div className="bg-[#fafafa] dark:bg-black">
      <Header darkMode={darkMode} setDarkMode={setDarkMode} />
      <main className="text-zinc-600 dark:text-zinc-400 bg-white dark:bg-zinc-900 w-full md:w-[90%] xl:w-[1024px] mx-auto md:border-x border-[#ececee] dark:border-zinc-600">
        <hr className="w-3/4 ml-[12.5%] xl:w-[60%] xl:ml-[20%] border-[#ececee] dark:border-zinc-600" />
        <About />
        <hr className="w-3/4 ml-[12.5%] xl:w-[60%] xl:ml-[20%] my-12 border-[#ececee] dark:border-zinc-600" />
        <Projects />
        <hr className="hidden md:block w-3/4 ml-[12.5%] xl:w-[60%] xl:ml-[20%] border-[#ececee] dark:border-zinc-600" />
      </main>
      <Footer />
    </div>
  );
}

export default App;
