import React from "react";

import Logos from "../components/Logos";

const About = () => {
  return (
    <section
      id="about"
      className="mt-12 px-4 md:pl-12 md:pr-20 xl:pl-20 xl:pr-36"
    >
      <h1 className="text-4xl font-semibold text-zinc-400 dark:text-zinc-100">
        Full stack <span className="text-[#4ab2f1]">software engineer</span>
      </h1>
      <p className="mt-8">
        Building applications with JavaScript, TypeScript, React, Next.js,
        Node.js, Express.js, NestJS, PostgreSQL, and MongoDB.
      </p>
      <p className="mt-6">
        B.S. in Software Engineering, 2024. Graduate of Fullstack Academy, 2022.
      </p>
      <Logos />
    </section>
  );
};

export default About;
