import React from "react";
import { SunIcon } from "@heroicons/react/24/outline";
import { MoonIcon } from "@heroicons/react/24/outline";
import Portrait from "./Portrait";
import Nav from "./Nav";

const Header = ({ darkMode, setDarkMode }) => {
  const handleClick = () => {
    if (darkMode) {
      setDarkMode(false);
      document.body.classList.remove("dark");
      localStorage.removeItem("isDarkMode");
    } else {
      setDarkMode(true);
      document.body.classList.add("dark");
      localStorage.setItem("isDarkMode", true);
    }
  };
  return (
    <header className="flex items-center justify-between text-zinc-600 w-full mx-auto md:w-[90%] xl:w-[1024px] py-6 xl:py-8 px-4 bg-white dark:bg-zinc-900 md:border-x border-[#ececee] dark:border-zinc-600">
      <>
        <Portrait />
        <span className="max-md:hidden ml-8 xl:ml-14 md:text-2xl text-5xl font-semibold text-[#4ab2f1]">
          Tim Kent
        </span>
      </>
      <Nav />

      <div className="group">
        <button onClick={handleClick}>
          <div className="border rounded-full h-[46px] w-[46px] mr-4 md:mr-10 xl:mr-16 ml-8 border-[#ececee] group-hover:border-yellow-300 dark:border-zinc-600 dark:group-hover:border-[#4ab2f1] transition-all duration-300">
            {darkMode ? (
              <MoonIcon className="ml-[9px] group-hover:ml-[6px] mt-[9px] group-hover:mt-[6px] h-[26px] group-hover:h-[32px] transition-height transition-stroke duration-300 stroke-zinc-100 group-hover:stroke-[#4ab2f1]" />
            ) : (
              <SunIcon className="ml-[9px] group-hover:ml-[6px] mt-[9px] group-hover:mt-[6px] h-[26px] group-hover:h-[32px] transition-height transition-stroke duration-300 stroke-yellow-400 group-hover:stroke-yellow-500" />
            )}
          </div>
        </button>
      </div>
    </header>
  );
};

export default Header;
