const data = [
  {
    name: "ListMaker",
    description:
      "Track goals and stay organized with ListMaker, a free-to-use shopping and to-do list-making application. ListMaker is in continuing development, with new features including additional list types planned.",
    siteUrl: "https://mylistmaker.netlify.app/",
    stack: [
      "TypeScript",
      "React",
      "React Query",
      "Tailwind CSS",
      "Google Maps API",
      "Node.js",
      "Express.js",
      "PostgreSQL",
    ],
    img: "listmaker.jpg",
  },
  {
    name: "Insects of Papua New Guinea",
    description:
      "Educational website showcasing a collection of images of over 1000 insect species documented in Papua New Guinea.",
    siteUrl: "https://www.pnginsects.com/",
    stack: [
      "TypeScript",
      "Next.js",
      "Zustand",
      "Tailwind CSS",
      "Node.js",
      "Express.js",
    ],
    img: "insectsofpng.jpg",
  },
  {
    name: "Historic Landmarks of San Francisco",
    description:
      "Content-driven website featuring articles, guides, and maps about the official California landmarks in San Francisco.",
    siteUrl: "https://www.hlosf.com/",
    stack: ["JavaScript", "Next.js", "React", "Tailwind CSS"],
    img: "hlosf.jpg",
  },
];

export default data;
