import React from "react";
import { EnvelopeIcon } from "@heroicons/react/24/solid";

import GitHubIcon from "../assets/icons/GitHubIcon";
import LinkedInIcon from "../assets/icons/LinkedInIcon";

const Logos = () => {
  return (
    <div className="mt-8">
      <div className="p-4 rounded-lg w-fit flex border border-[#ececee] dark:border-zinc-600 items-center gap-8">
        <div>
          <a href="https://github.com/tdkent">
            <GitHubIcon className="fill-zinc-600 dark:fill-zinc-400 h-8 hover:fill-[#4ab2f1] dark:hover:fill-[#4ab2f1]" />
          </a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/timkent1/">
            <LinkedInIcon className="fill-zinc-600 dark:fill-zinc-400 h-8 hover:fill-[#4ab2f1] dark:hover:fill-[#4ab2f1]" />
          </a>
        </div>
        <div>
          <a href="mailto:timkentdev@gmail.com">
            <EnvelopeIcon className="fill-zinc-600 dark:fill-zinc-400 h-8 hover:fill-[#4ab2f1] dark:hover:fill-[#4ab2f1]" />{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Logos;
