import React from "react";

const Portrait = () => {
  return (
    <div className="relative h-14 overflow-hidden aspect-square rounded-full border border-[#ececee] dark:border-zinc-600 md:hidden">
      <img src="portrait.webp" alt="Portrait" className="object-cover" />
    </div>
  );
};

export default Portrait;
